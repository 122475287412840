import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { ConfirmationModal, MessageModal, Selection } from '../../components';
import { UserContext } from '../../utils/contexts/UserContext';
import { DatePicker, Table } from 'antd';
import { cashSaleTableHeader } from '../../utils/tableData/CashSalesTableHeader';
import { saveUploadedCashSales } from '../../utils/api/CashSalesAPI';

export default function CashSalesUploading() {
  const {
    profile,
    approvedCashSalesList,
    loadApprovedCashSales,
    buildings,
    loadBuildings,
  } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(false);

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const [localApprovedCashSaleList, setLocalApprovedCashSaleList] = useState(
    []
  );

  const [selectedBuilding, setSelectedBuilding] = useState('');

  const [selectedFromDate, setSelectedFromDate] = useState('');
  const [selectedToDate, setSelectedToDate] = useState('');

  const [localBuildingsList, setLocalBuildingsList] = useState([]);

  // For message modal.
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [modalBody, setModalBody] = useState('');

  const data = localApprovedCashSaleList.map((data, index) => {
    return { ...data, key: index };
  });

  const handleUploadToNetSuite = async () => {
    setIsLoading(true);
    setIsConfirmationModalOpen(false);
    let uploadedCashSales = [];

    localApprovedCashSaleList.forEach((record) => {
      const uploadedCashSale = { invoiceId: record.invoiceId };
      uploadedCashSales.push(uploadedCashSale);
    });
    const response = await saveUploadedCashSales(uploadedCashSales);

    if (response.isSuccessful) {
      resetData();
    } else {
      // TODO create an error handler
      alert(
        'An error occured. Please contact system administrator immediately.'
      );
    }

    setIsLoading(false);
  };

  const resetData = () => {
    setSelectedBuilding('');
    setSelectedFromDate('');
    setSelectedToDate('');

    setLocalApprovedCashSaleList([]);
  };

  useEffect(() => {
    setLocalApprovedCashSaleList(approvedCashSalesList);
  }, [approvedCashSalesList]);

  useEffect(() => {
    setLocalBuildingsList(buildings);
  }, [buildings]);

  useEffect(() => {
    if (buildings.length === 0) {
      loadBuildings();
    }
  }, []);

  return (
    <Container className="pt-3">
      <h2>Cash Sales - Upload to NetSuite</h2>
      <hr />
      <Row>
        <Col xs={3}>
          <Selection
            title={'Building'}
            disabled={isLoading}
            value={selectedBuilding}
            onChange={(e) => {
              setSelectedBuilding(e.target.value);
            }}
            options={localBuildingsList.map((building) => ({
              value: building.buildingCode,
              name: building.buildingName,
            }))}
          />
        </Col>
        <Col>
          <DatePicker.RangePicker
            onChange={(dates) => {
              const [start, end] = dates;
              setSelectedFromDate(start.format('YYYY-MM-DD'));
              setSelectedToDate(end.format('YYYY-MM-DD'));
            }}
            format="YYYY-MM-DD"
            placeholder={['Start Date', 'End Date']}
            style={{ height: '40px', width: '100%' }}
            disabled={isLoading}
          />
        </Col>
        <Col>
          <Button
            variant="secondary"
            disabled={
              isLoading ||
              selectedBuilding === '' ||
              selectedFromDate === '' ||
              selectedToDate === ''
            }
            onClick={async () => {
              setIsLoading(true);
              await loadApprovedCashSales(
                selectedFromDate,
                selectedToDate,
                selectedBuilding
              );
              setIsLoading(false);
            }}
            className="mb-3 w-100"
          >
            {isLoading ? 'Loading...' : 'Process'}
          </Button>
        </Col>
        <Row className="pe-0">
          <Col className="w-100 text-center pe-0">
            {localApprovedCashSaleList.length > 0 && (
              <Button
                variant="primary"
                disabled={isLoading}
                onClick={() => setIsConfirmationModalOpen(true)}
                className="mb-3 w-100 pms-bg"
              >
                <strong>
                  {isLoading ? 'Saving...' : 'Upload to NetSuite!'}
                </strong>
              </Button>
            )}
          </Col>
        </Row>
      </Row>
      <Table
        columns={cashSaleTableHeader}
        dataSource={data}
        size="small"
        bordered="true"
        scroll={{ x: true }}
        loading={isLoading}
      />
      <ConfirmationModal
        isModalOpen={isConfirmationModalOpen}
        setIsModalOpen={setIsConfirmationModalOpen}
        title={'Upload to NetSuite?'}
        body={
          'Please confirm that all the data included on the table should be uploaded to NetSuite. REVERT IS NOT an option.'
        }
        handleConfirm={handleUploadToNetSuite}
        confirmationVariant={'danger'}
      />
      <MessageModal
        isModalOpen={isMessageModalOpen}
        setIsModalOpen={setIsMessageModalOpen}
        title={'NetSuite Invoice'}
        body={modalBody}
      />
    </Container>
  );
}
